export default [
    {
        name: 'PLCController',
        path: '/PLCController',
        meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
        component: () => import('@/views/PLC/mainPage.vue'),
        redirect: "/PLCController/data",
        children: [
            {
                path: '/PLCController/data',
                meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                component: () => import('@/views/PLC/originalData.vue'),
            },
            {
                path: '/PLCController/dataparsing',
                meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                component: () => import('@/views/PLC/dataParsing.vue'),
            },
            {
                path: '/PLCController/monitorDataParsing',
                meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                component: () => import('@/views/PLC/monitorDataParsing.vue'),
            },
            {
                path: '/PLCController/datasend',
                meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                component: () => import('@/views/PLC/dataSend.vue'),
                redirect: "/PLCController/datasend/readData",
                children: [
                    {
                        path: '/PLCController/datasend/readData',
                        meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                        component: () => import('@/views/PLC/operating.vue'),
                        props: (route) => ({op: 'readData'}),
                    },
                    {
                        path: '/PLCController/datasend/writeData',
                        meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                        component: () => import('@/views/PLC/operating.vue'),
                        props: (route) => ({op: 'writeData'}),
                    },
                    {
                        path: '/PLCController/datasend/setStatus',
                        meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                        component: () => import('@/views/PLC/operating.vue'),
                        props: (route) => ({op: 'status'}),
                    },
                    {
                        path: '/PLCController/datasend/upgrade',
                        meta: {requireAuth: true, roles: ['SA', 'AD_TR']},
                        component: () => import('@/views/PLC/operating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                ]
            },
        ]
    }
]
