export default [
    {
        name: 'GatewayVisitor',
        path: '/GatewayVisitor',
        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
        component: () => import('@/views/GatewayVisitor/mainPage.vue'),
        redirect: "/GatewayVisitor/data",
        children: [
            {
                path: '/GatewayVisitor/data',
                meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                component: () => import('@/views/GatewayVisitor/originalData.vue'),
            },
            {
                path: '/GatewayVisitor/dataparsing',
                meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                component: () => import('@/views/GatewayVisitor/dataParsing.vue'),
            },
            {
                path: '/GatewayVisitor/datasend',
                meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                component: () => import('@/views/GatewayVisitor/dataSend.vue'),
                redirect: "/GatewayVisitor/datasend/networking",
                children: [
                    {
                        path: '/GatewayVisitor/datasend/networking',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/operating.vue'),
                        props: (route) => ({op: 'networking'}),
                    },
                    {
                        path: '/GatewayVisitor/datasend/readData',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/operating.vue'),
                        props: (route) => ({op: 'readData'}),
                    },
                    {
                        path: '/GatewayVisitor/datasend/wifi',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/operating.vue'),
                        props: (route) => ({op: 'wifi'}),
                    },
                    {
                        path: '/GatewayVisitor/datasend/upgrade',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/operating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                    {
                        path: '/GatewayVisitor/datasend/threshold',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/operating.vue'),
                        props: (route) => ({op: 'threshold'}),
                    },
                ]
            },
            {
                path: '/GatewayVisitor/mesh',
                meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                component: () => import('@/views/GatewayVisitor/meshDataSend.vue'),
                redirect: "/GatewayVisitor/mesh/networking",
                children: [
                    {
                        path: '/GatewayVisitor/mesh/networking',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/meshOperating.vue'),
                        props: (route) => ({op: 'networking', sn: route.query.sn}),
                    },
                    {
                        path: '/GatewayVisitor/mesh/specifyNetworking',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/meshOperating.vue'),
                        props: (route) => ({op: 'specifyNetworking'}),
                    },
                    {
                        path: '/GatewayVisitor/mesh/meshSetStatus',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/meshOperating.vue'),
                        props: (route) => ({op: 'meshSetStatus'}),
                    },
                    {
                        path: '/GatewayVisitor/mesh/upgrade',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/meshOperating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                    {
                        path: '/GatewayVisitor/mesh/plantPolyline',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/meshOperating.vue'),
                        props: (route) => ({op: 'plantPolyline'}),
                    },
                    {
                        path: '/GatewayVisitor/mesh/gatewayList',
                        meta: {requireAuth: true, roles: ['SA', 'AD_GV']},
                        component: () => import('@/views/GatewayVisitor/meshOperating.vue'),
                        props: (route) => ({op: 'gatewayList'}),
                    },
                ]
            },
        ]
    }
]
