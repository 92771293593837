export default [
    {
        name: 'microinverter',
        path: '/microinverter',
        meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
        component: () => import('@/views/microinverter/mainPage.vue'),
        redirect: "/microinverter/data",
        children: [
            {
                path: '/microinverter/data',
                meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                component: () => import('@/views/microinverter/originalData.vue'),
            },
            {
                path: '/microinverter/dataparsing',
                meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                component: () => import('@/views/microinverter/dataParsing.vue'),
            },
            {
                path: '/microinverter/datasend',
                meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                component: () => import('@/views/microinverter/dataSend.vue'),
                redirect: "/microinverter/datasend/readData",
                children: [
                    {
                        path: '/microinverter/datasend/readData',
                        meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                        component: () => import('@/views/microinverter/operating.vue'),
                        props: (route) => ({op: 'readData', sn: route.query.sn}),
                    },
                    {
                        path: '/microinverter/datasend/writeData',
                        meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                        component: () => import('@/views/microinverter/operating.vue'),
                        props: (route) => ({op: 'writeData'}),
                    },
                    {
                        path: '/microinverter/datasend/setStatus',
                        meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                        component: () => import('@/views/microinverter/operating.vue'),
                        props: (route) => ({op: 'status'}),
                    },
                    {
                        path: '/microinverter/datasend/upgrade',
                        meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                        component: () => import('@/views/microinverter/operating.vue'),
                        props: (route) => ({op: 'upgrade'}),
                    },
                    {
                        path: '/microinverter/datasend/moduleOTA',
                        meta: {requireAuth: true, roles: ['SA', 'AD_JB']},
                        component: () => import('@/views/microinverter/operating.vue'),
                        props: (route) => ({op: 'moduleOTA'}),
                    },
                ]
            },
        ]
    }
]
